<template>
  <div>
    <SellingReport />
  </div>
</template>

<script>
import SellingReport from '@/components/SellingReport.vue';


export default {
  components: { SellingReport },
}

</script>
